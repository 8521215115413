import React from "react";
import "twin.macro";
import { BlogWrapper } from "../components/pages/blog";
import { graphql, PageProps } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { SEO } from "../components";

const Blog: React.FC<PageProps> = ({ data, pageContext }) => {
  const blogImages = data.blogImages.edges.map((a) => a.node);
  const legacyPostsWithImages = pageContext.legacyPosts
    .map((a) => {
      const imageData = blogImages.find(
        (b) => b.relativePath === a.imageRelativePath
      );
      return { ...a, image: { localFile: getImage(imageData) } };
    })
    .sort((a, b) => b.publishTime.localeCompare(a.publishTime));

  const newPosts = data.allStrapiBlogpost.edges.map(({ node }) => {
    if (!node.image) console.log(node.title);

    return {
      ...node,
      contentHtml: node.content.data.content,
      categoryName: node.category.name,
      categorySlug: `/blog/${node.category.slug}`,
      image: {
        ...node.image,
        localFile: getImage(node.image.localFile),
      },
    };
  });
  return (
    <>
      <SEO title={`Here & Now | ${pageContext.categoryName}`} />
      <main>
        <section aria-label="Here And Now Blog">
          <div tw="bg-sonic">
            <div tw="max-w-screen-xl mx-auto px-4 pb-12">
              <BlogWrapper
                posts={[...newPosts, ...legacyPostsWithImages]}
                category={pageContext.categoryName}
              />
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Blog;

export const query = graphql`
  query BlogCategoryPageQuery($categoryName: String!) {
    allStrapiBlogpost(filter: { category: { name: { eq: $categoryName } } }) {
      edges {
        node {
          category {
            name
            slug
          }
          content {
            data {
              content
            }
          }
          createdAt
          description
          id
          publishTime
          publishedAt
          slug
          title
          updatedAt
          image {
            alternativeText
            caption
            localFile {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED, width: 860, quality: 100)
              }
            }
          }
        }
      }
    }
    blogImages: allFile(filter: { relativeDirectory: { eq: "blog" } }) {
      edges {
        node {
          relativePath
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 415, quality: 90)
          }
        }
      }
    }
  }
`;
